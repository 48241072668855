<template>
<div>
  <!-- 面包屑导航区域 -->
  <el-breadcrumb separator="/">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>权限管理</el-breadcrumb-item>
    <el-breadcrumb-item>角色列表</el-breadcrumb-item>
  </el-breadcrumb>
  <!-- 卡片视图 -->
  <el-card>
    <!-- 添加角色按钮区域 -->
    <el-row>
      <el-col>
        <el-button type="primary" @click="showAddRoleDialog()">添加角色</el-button>
      </el-col>
    </el-row>
    <el-table :data="roleList" border stripe>
      <!-- 展开列 -->
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-row :class="['bdbottom', i1 ===0 ? 'bdtop' : '','vcenter']" v-for="(item1, i1) in scope.row.children" :key="item1.id">
            <!-- 渲染一级权限 -->
            <el-col :span="5">
              <el-tag closable @close="removeRightById(scope.row, item1.id)">{{item1.authName}}</el-tag>
              <i class="el-icon-caret-right"></i>
            </el-col>
            <!-- 渲染二级和三级权限 -->
            <el-col :span="19">
              <!-- for循环嵌套渲染二级权限 -->
              <el-row :class="[i2 === 0 ? '': 'bdtop','vcenter']" v-for="(item2, i2) in item1.children" :key="item2.id">
                <el-col :span="6">
                  <el-tag closable  @close="removeRightById(scope.row, item2.id)" type="success">{{item1.authName}}</el-tag>
                  <i class="el-icon-caret-right"></i>
                </el-col>
                <el-col :span="18">
                  <el-tag @close="removeRightById(scope.row, item3.id)" closable type="warning" v-for="(item3) in item2.children" :key="item3.id">
                    {{item3.authName}}
                  </el-tag>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <!-- 索引列 -->
      <el-table-column type="index"></el-table-column>
      <el-table-column label="角色名称" prop="roleName"></el-table-column>
      <el-table-column label="角色秒描述" prop="roleDesc"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" icon="el-icon-edit" @click="showEditDialog(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeRoleById(scope.row.id)">删除</el-button>
          <el-button size="mini" type="warning" icon="el-icon-setting" @click="showSetRightDialog(scope.row)">分配权限</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <!-- 添加角色弹框 -->
  <el-dialog
  title="添加角色"
  :visible.sync="addRoleVisible"
  @close="addRoleClosed()"
  width="50%">
  <el-form ref="addFormRef" :rules="addFormRules" :model="addForm" label-width="80px">
    <el-form-item label="角色名称" prop="roleName">
      <el-input v-model="addForm.roleName"></el-input>
    </el-form-item>
     <el-form-item label="角色描述" prop="roleDesc">
      <el-input v-model="addForm.roleDesc"></el-input>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="addRoleVisible = false">取 消</el-button>
    <el-button type="primary" @click="addRole()">确 定</el-button>
  </span>
</el-dialog>
<!-- 修改角色信息的弹出框 -->
<el-dialog
  title="修改角色"
  :visible.sync="editDialogVisible"
  @close="editRoleClosed()"
  width="50%">
  <el-form ref="editFormRef" :rules="addFormRules" :model="editRole" label-width="80px">
    <el-form-item label="角色名称" prop="roleName">
      <el-input v-model="editRole.roleName"></el-input>
    </el-form-item>
     <el-form-item label="角色描述" prop="roleDesc">
      <el-input v-model="editRole.roleDesc"></el-input>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="editRoles()">确 定</el-button>
  </span>
</el-dialog>
  <!-- 分配权限弹框 -->
  <el-dialog
    @close="setRightDialogClosed()"
    title="分配权限"
    :visible.sync="setRightDialogVisible"
    width="50%">
    <!-- 树形控件 -->
    <el-tree ref="treeRef" :data="rightsList" :props="treeProps" show-checkbox node-key="id" :default-expand-all="true" :default-checked-keys="defKeys"></el-tree>
    <span slot="footer" class="dialog-footer">
      <el-button @click="setRightDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="allotRights">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
export default {
  data() {
    return {
      // 角色列表
      roleList: [],
      // 控制分配权限的对话框
      setRightDialogVisible: false,
      // 所以权限的数据
      rightsList: [],
      // 树形控件树形绑定对象
      treeProps: {
        label: 'authName',
        children: 'children'
      },
      // 默认选中的节点ID值数组
      defKeys: [],
      // 当前即将分配权限的ID
      roleId: '',
      // 控制添加用户的对话框
      addRoleVisible: false,
      // 添加角色表单
      addForm: {
        roleName: '',
        roleDesc: ''
      },
      // 添加表单的校验校验规则
      addFormRules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur' }
        ],
        roleDesc: [
          { required: true, message: '请输入角色描述', trigger: 'blur' }
        ]
      },
      // 控制修改角色信息的弹出框
      editDialogVisible: false,
      // 修改角色的数据
      editRole: {
        id: 0,
        roleName: '',
        roleDesc: ''
      }
    }
  },
  created() {
    this.getRolesList()
  },
  methods: {
    // 获取所有角色的列表
    async getRolesList () {
      const { data: res } = await this.$http.get('roles')
      if (res.meta.status !== 200) {
        return this.$message.error('获取角色列表失败！')
      }
      this.roleList = res.data
      console.log(this.roleList)
    },
    // 根据ID删除对应的权限
    async removeRightById (role, rightId) {
      // 弹框提示用户是够要删除
      const confirmResult = await this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('取消了删除！')
      }
      const { data: res } = await this.$http.delete(`roles/${role.id}/rights/${rightId}`)
      if (res.meta.status !== 200) {
        return this.$message.error('删除权限失败！')
      }
      role.children = res.data
    },
    // 展示分配权限的对话框
    async showSetRightDialog (role) {
      this.roleId = role.id
      // 获取所有权限的数据
      const { data: res } = await this.$http.get('rights/tree')
      if (res.meta.status !== 200) {
        return this.$message.error('获取权限失败！')
      }
      // 获取到的权限数据，保存到data中
      this.rightsList = res.data
      // 递归获取三级节点的ID
      this.getLeafKeys(role, this.defKeys)
      this.setRightDialogVisible = true
    },
    // 通过递归的形式，获取角色所有三级权限的ID，并保存到数组中
    getLeafKeys (node, arr) {
      // 如果当前node节点不包含children属性，则为三级节点
      if (!node.children) {
        return arr.push(node.id)
      }
      node.children.forEach(item => this.getLeafKeys(item, arr))
    },
    // 监听分配权限对话框关闭事件
    setRightDialogClosed () {
      this.defKeys = []
    },
    // 点击为角色分配权限
    async allotRights () {
      const keys = [
        ...this.$refs.treeRef.getCheckedKeys(),
        ...this.$refs.treeRef.getHalfCheckedKeys()
      ]
      const idStr = keys.join(',')
      const { data: res } = await this.$http.post(`roles/${this.roleId}/rights`, { rids: idStr })
      if (res.meta.status !== 200) {
        return this.$message.error('分配权限失败！')
      }
      this.$message.success('分配权限成功！')
      this.getRolesList()
      this.setRightDialogVisible = false
    },
    // 添加角色按钮监听事件
    showAddRoleDialog () {
      this.addRoleVisible = true
    },
    // 添加角色按钮点击事件
    addRole() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('roles', this.addForm)
        if (!res.meta.status) {
          return this.$message.error('添加角色失败！')
        }
        this.$message.success('添加角色成功！')
        this.addRoleVisible = false
        this.getRolesList()
      })
    },
    // 添加角色弹框关闭事件
    addRoleClosed () {
      this.$refs.addFormRef.resetFields()
    },
    // 删除角色按钮
    async removeRoleById (id) {
      const confirmResult = await this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('取消了删除角色！')
      }
      const { data: res } = await this.$http.delete('roles/' + id)
      if (res.meta.status !== 200) {
        return this.$message.error('删除角色失败！')
      }
      this.$message.success('删除角色成功！')
      this.getRolesList()
    },
    // 修改角色弹框关闭事件
    editRoleClosed () {
      this.$refs.editFormRef.resetFields()
    },
    // 修改页面弹出
    showEditDialog (row) {
      this.editRole.id = row.id
      this.editRole.roleName = row.roleName
      this.editRole.roleDesc = row.roleDesc
      this.editDialogVisible = true
    },
    // 修改页面确定按钮
    editRoles () {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('roles/' + this.editRole.id, {
          roleName: this.editRole.roleName,
          roleDesc: this.editRole.roleDesc
        })
        if (res.meta.status !== 200) {
          return this.$message.error('修改角色失败！')
        }
        this.$message.success('修改角色成功！')
        this.getRolesList()
        this.editDialogVisible = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-tag {
  margin: 7px;
}
.bdtop {
  border-top: 1px solid #eee;
}
.bdbottom {
  border-bottom: 1px solid #eee;
}
.vcenter {
  display: flex;
  align-items: center;
}
.el-table {
  margin-top: 20px;
}
</style>
